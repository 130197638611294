<template>
  <div v-if="cartModalList && cartModalList.length">
    <s-drawer
      v-if="isNewShopBagPopTypeByAbt && !IS_RW"
      v-model:visible="shoppingBagModalShow"
      class="shopbag-coupon-drawer"
      direction="rtl"
      size="400px"
      :append-to-body="true"
    >
      <template #title>
        <strong
          class="bag-list-title"
        >
          {{
            language.SHEIN_KEY_PC_25384
          }}
        </strong>
        <div class="sub-title">
          <div
            v-if="almostSoldoutTips"
            class="effiency-soldout"
            v-html="almostSoldoutTips"
          >
          </div>
          <div 
            v-else
            class="effiency-total"
          >
            {{ cartProductTotal(cartModalList) }}
          </div>
        </div>
        <div class="sub-title-bottom">
          <div
            v-if="showSensitiveTips" 
            class="shopping-bag__sensitive"
          >
            <Icon
              class="shopping-bag__sensitive_icon"
              :name="'sui_icon_sensitive_16px'"
              :size="'16px'"
              :color="'#CFA027'"
            />
            <span 
              :class="['shopping-bag__tips_flex', isNewShopBagPopTypeByAbt && !IS_RW ? 'shopping-bage__sensitive_new' : '']"
              v-html="language.SHEIN_KEY_PC_28259"
            ></span>
          </div>
          <!-- 物流延迟公告提示 -->
          <div 
            v-if="announcements.length"
            :class="['shopping-bag__tips', quickShipCheckoutStatus.quickShipStyle && 'shopping-bag__tips-bg']"
          >
            <div
              v-for="(item, index) in announcements"
              :key="index"
              :class="['shopping-bag__tips_row']"
            >
              <div
                v-if="quickShipCheckoutStatus.quickShipStyle"
                class="shopping-bag_newContainer"
                @click.stop="showQuickPop(item?.icon?.type === 'quickShip')"
              >
                <span :class="['shopping-bag__tips-p0']">
                  <s-label
                    v-if="item.label" 
                    :type="item.labelType"
                  >
                    {{ item.label }}
                  </s-label>
                  <quickShipLabel
                    v-if="item?.icon?.type === 'quickShip'"
                    :text="item?.icon?.label"
                    :style-config="item?.icon?.style"
                    :show-line="true"
                  />
                  <Icon
                    v-else-if="item.icon"
                    class="shopping-bag__tips_icon"
                    :name="item.icon.name"
                    :size="item.icon.size"
                    :color="item.icon.color"
                  />
                </span>
                <span
                  v-if="item?.icon?.type === 'quickShip'"
                  :ref="quickShipTextRef"
                  :class="['shopping-bag__tips_flex', 'shopping-bag__tips_flex_line_limit']"
                  :style="{lineHeight: `${quickShipLineHeight}px`}"
                  v-html="item.text"
                >
                </span>
                <span
                  v-else
                  :class="['shopping-bag__tips_flex']" 
                  v-html="item.text"
                ></span>
              </div>
              <template v-else>
                <div class="bag-list__tips_cell">
                  <s-label
                    v-if="item.label" 
                    class="bag-list__tips_icon"
                    :type="item.labelType"
                  >
                    {{ item.label }}
                  </s-label>
                  <Icon
                    v-else-if="item.icon"
                    class="bag-list__tips_icon"
                    style="margin-left: 6px;"
                    :name="item.icon.name"
                    :size="item.icon.size"
                    :color="item.icon.color"
                  />
                </div>
                <div 
                  class="bag-list__tips_cell bag-list__tips_text" 
                  v-html="item.text"
                ></div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="mergeCartsList && mergeCartsList.length"
        class="shopbag-drawer-list"
      >
        <template
          v-for="(eachStore, index) in mergeCartsList"
          :key="index"
        >
          <div class="shopbag-list-body">
            <div
              class="shopbag-list-content"
            >
              <div
                :key="`bag-title-${index}`"
                class="shop-platform-title"
              >
                <div class="store-business-wrap">
                  <strong class="store-info-content">
                    <preferred-seller-store-icon v-if="isPreferredSellerStore(eachStore)" />
                    <Icon
                      v-else
                      name="sui_icon_store_20px"
                      size="20px"
                      color="#303030"
                    />
                    <span
                      class="store-name"
                      :class="isNewShopBagPopTypeByAbt && !IS_RW ? 'store-name-new' : ''"
                    >
                      {{ eachStore[0].store_title || eachStore[0].store_name }}
                    </span>
                  </strong>
                  <div 
                    v-if="showStoreSellerInfo"
                    v-tap="analysisData('1-11-2-2', eachStore[0].store_code)" 
                    v-expose="analysisData('1-11-2-1', eachStore[0].store_code)"
                    class="business-info-wrap"
                    @click="openMerchantInfoModal(eachStore[0])"
                  >
                    <span>{{ language.SHEIN_KEY_PC_25988 }}</span>
                    <Icon
                      class="business-info-wrap__icon"
                      name="sui_icon_doubt_12px_2"
                      size="10px"
                      color="#959595"
                    />
                  </div>
                </div>
                <span class="store-product-total">{{ cartProductTotal(eachStore) }}</span>
              </div>
              <div
                :key="`content-${index}`"
                class="carts-container"
              >
                <checkout-cart-item
                  v-for="(item, inx) in eachStore"
                  :key="`checkout-cart-item-${inx}`"
                  :chemicals-ids="chemicalsIds"
                  :tag-ids-map="tagIdsMap"
                  :class="isNewShopBagPopTypeByAbt && !IS_RW ? 'drawer-item-effiency' : 'carts-item-effiency'"
                  :item="item"
                  :is-bag-modal="true"
                  :index="inx"
                  :language="language"
                  :shopping-bag-modal-show="shoppingBagModalShow"
                  :checkout="checkout"
                  :can-edit-shoppingbag="canEditShoppingbag"
                  :is-last-goods="isLastGoods"
                  source="inner"
                  :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
                  :discount-logo-by-abt="discountLogoByAbt"
                  :under-price-abt-value="underPriceAbtValue"
                  :is-new-shop-bag-pop-type-by-abt="isNewShopBagPopTypeByAbt"
                  :is-suggested="isSuggested"
                  :checkout-scene="checkoutScene"
                  :locals="locals"
                  :switch-on-evolu-shein="switchOnEvoluSHEIN"
                  :evolu-shein-id="EVOLU_SHEIN_ID"
                  :show-arrival-price-by-abt="showArrivalPriceByAbt"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </s-drawer>

    <s-dialog
      v-if="!isNewShopBagPopTypeByAbt || IS_RW"
      v-model:visible="shoppingBagModalShow"
      :type="'W480'"
      :show-close="true"
      :close-on-click-modal="false"
      :is-scroll="true"
      append-to-body
      class="bag-list__effiency"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_25384 }}
      </template>
      <template #subtitle>
        <div
          v-if="almostSoldoutTips" 
          class="bag-list__soldout"
          v-html="almostSoldoutTips"
        >
        </div>
        <div 
          v-else
          class="bag-list__effiency-total"
        >
          {{ cartProductTotal(cartModalList) }}
        </div>
        <div
          v-if="showSensitiveTips" 
          class="shopping-bag__sensitive"
        >
          <Icon
            class="shopping-bag__sensitive_icon"
            :name="'sui_icon_sensitive_16px'"
            :size="'16px'"
            :color="'#CFA027'"
          />
          <span 
            :class="['shopping-bag__tips_flex']"
            v-html="language.SHEIN_KEY_PC_28259"
          ></span>
        </div>
        <!-- 物流延迟公告提示 -->
        <div 
          v-if="announcements.length"
          :class="['shopping-bag__tips', quickShipCheckoutStatus.quickShipStyle && 'shopping-bag__tips-bg']"
        >
          <div
            v-for="(item, index) in announcements"
            :key="index"
            :class="['shopping-bag__tips_row']"
          >
            <div
              v-if="quickShipCheckoutStatus.quickShipStyle"
              class="shopping-bag_newContainer"
              @click.stop="showQuickPop(item?.icon?.type === 'quickShip')"
            >
              <span :class="['shopping-bag__tips-p0']">
                <s-label
                  v-if="item.label" 
                  :type="item.labelType"
                >
                  {{ item.label }}
                </s-label>
                <quickShipLabel
                  v-if="item?.icon?.type === 'quickShip'"
                  :text="item?.icon?.label"
                  :style-config="item?.icon?.style"
                  :show-line="true"
                />
                <Icon
                  v-else-if="item.icon"
                  class="shopping-bag__tips_icon"
                  :name="item.icon.name"
                  :size="item.icon.size"
                  :color="item.icon.color"
                />
              </span>
              <span
                v-if="item?.icon?.type === 'quickShip'"
                :ref="quickShipTextRef"
                :class="['shopping-bag__tips_flex', 'shopping-bag__tips_flex_line_limit']"
                :style="{lineHeight: `${quickShipLineHeight}px`}"
                v-html="item.text">
              </span>
              <span
                v-else
                :class="['shopping-bag__tips_flex']" 
                v-html="item.text"
              ></span>
            </div>
            <template v-else>
              <div class="bag-list__tips_cell">
                <s-label
                  v-if="item.label" 
                  class="bag-list__tips_icon"
                  :type="item.labelType"
                >
                  {{ item.label }}
                </s-label>
                <Icon
                  v-else-if="item.icon"
                  class="bag-list__tips_icon"
                  style="margin-left: 6px;"
                  :name="item.icon.name"
                  :size="item.icon.size"
                  :color="item.icon.color"
                />
              </div>
              <div 
                class="bag-list__tips_cell bag-list__tips_text" 
                v-html="item.text"
              ></div>
            </template>
          </div>
        </div>
      </template>

      <div
        v-if="mergeCartsList && mergeCartsList.length"
        class="shopping-bag-list"
      >
        <template
          v-for="(eachStore, index) in mergeCartsList"
          :key="index"
        >
          <div class="bag-list-body">
            <div
              class="bag-list-content j-bag-list-content"
            >
              <div
                :key="`title-${index}`"
                class="platform-title"
              >
                <div class="store-business-container">
                  <strong class="store-info-content">
                    <preferred-seller-store-icon v-if="isPreferredSellerStore(eachStore)" />
                    <Icon
                      v-else
                      name="sui_icon_store_20px"
                      size="20px"
                      color="#303030"
                    />
                    {{ eachStore[0].store_title || eachStore[0].store_name }}
                  </strong>
                  <div 
                    v-if="showStoreSellerInfo"
                    v-tap="analysisData('1-11-2-2', eachStore[0].store_code)" 
                    v-expose="analysisData('1-11-2-1', eachStore[0].store_code)"
                    class="business-info-content"
                    @click="openMerchantInfoModal(eachStore[0])"
                  >
                    <span>{{ language.SHEIN_KEY_PC_25988 }}</span>
                    <Icon
                      class="business-info-content__icon"
                      name="sui_icon_doubt_12px_2"
                      size="12px"
                      color="#222222"
                    />
                  </div>
                </div>
                <span style="white-space: nowrap;">{{ cartProductTotal(eachStore) }}</span>
              </div>
              <div
                :key="`content-${index}`"
                class="carts-wrapper"
              >
                <checkout-cart-item
                  v-for="(item, inx) in eachStore"
                  :key="`checkout-cart-item-${inx}`"
                  :chemicals-ids="chemicalsIds"
                  :tag-ids-map="tagIdsMap"
                  class="carts-item-effiency"
                  :item="item"
                  :is-bag-modal="true"
                  :index="inx"
                  :language="language"
                  :shopping-bag-modal-show="shoppingBagModalShow"
                  :checkout="checkout"
                  :can-edit-shoppingbag="canEditShoppingbag"
                  :is-last-goods="isLastGoods"
                  source="inner"
                  :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
                  :discount-logo-by-abt="discountLogoByAbt"
                  :under-price-abt-value="underPriceAbtValue"
                  :is-suggested="isSuggested"
                  :checkout-scene="checkoutScene"
                  :locals="locals"
                  :switch-on-evolu-shein="switchOnEvoluSHEIN"
                  :evolu-shein-id="EVOLU_SHEIN_ID"
                  :show-arrival-price-by-abt="showArrivalPriceByAbt"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </s-dialog>
    <s-dialog
      v-model:visible="merchantInfoModalShow"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_25988 }}
      </template>
      <div class="merchant-content">
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25994 }}
          <span 
            class="item-val"
            :class="{'is-block': bussinessName}"
          >{{ bussinessName || language.SHEIN_KEY_PC_25999 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25990 }}
          <span 
            class="item-val" 
            :class="{'is-block': bussinessAddress}"
          >{{ bussinessAddress || language.SHEIN_KEY_PC_25999 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25996 }}
          <span
            class="item-val"
            :class="{'is-block': bussinessRegistrationNumber}"
          >{{ bussinessRegistrationNumber || language.SHEIN_KEY_PC_26000 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25998 }}
          <span
            class="item-val"
            :class="{'is-block': vatNumber}"
          >{{ vatNumber || language.SHEIN_KEY_PC_26000 }}</span>
        </div>
        <p class="desc">
          {{ language.SHEIN_KEY_PC_26003 }}
        </p>
        <p class="desc">
          {{ language.SHEIN_KEY_PC_26004 }}
        </p>
      </div>
      <template #footer>
        <SButton 
          :type="['primary']"
          @click="closeMmerchantInfoModalShow"
        >
          {{ language.SHEIN_KEY_PC_15216 }} 
        </SButton>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import CheckoutCartItem from 'public/src/pages/checkout/vue_tpls/shopping_bag/CheckoutCartItem.vue'
import preferredSellerStoreIcon from 'public/src/pages/cart_new/components/preferredSellerStoreIcon.vue'
import quickShipLabel from 'public/src/pages/checkout/components/atom/quickShipLabel.vue'
import { Icon } from '@shein-aidc/icon-vue3'

import { filterPromitionGoods } from 'public/src/pages/checkout/utils.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
daEventCenter.addSubscriber({ modulecode: '1-7-7' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-11-2' })


export default {
  name: 'ShoppingBagList',
  components: {
    CheckoutCartItem,
    preferredSellerStoreIcon,
    quickShipLabel,
    Icon
  },
  props: {
    cartModalList: {
      type: Array,
      default: () => [],
    },
    cartsMallInfo: {
      type: Object,
      default: () => {},
    },
    chemicalsIds: {
      type: Array,
      default: () => [],
    },
    tagIdsMap: {
      type: Object,
      default: () => {},
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    canEditShoppingbag: {
      type: Boolean,
      default: false
    },
    shoppingBagCrossedPriceByAbt: {
      type: Boolean,
      default: false
    },
    discountLogoByAbt: {
      type: Boolean,
      default: false
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: [Boolean, String],
      default: false
    },
    checkoutScene: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      shoppingBagModalShow: false,
      merchantInfoModalShow: false,
      merchantInfoStore: {},
      quickShipTextRef: 'EffiencyBagList~vueshopping-bag__tips_flex',
      quickShipLineHeight: 14,
      quickShipFontSizeSmall: '11px', // 尝试过按照设计稿10px，太小了眼睛都瞎了
    }
  },
  computed: {
    ...mapState([
      'defaultShipping', 
      'checkout',
      'language',
      'locals',
    ]),
    ...mapGetters(['userBehaviorAbt', 'quickShipCheckoutStatus', 'switchOnEvoluSHEIN', 'EVOLU_SHEIN_ID', 'showArrivalPriceByAbt', 'isNewShopBagPopTypeByAbt']),
    mallCode() {
      return this.cartModalList?.[0]?.mall_code || ''
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    isLastGoods() {
      // 是否是最后一个商品（当前结算车内最后一个，并非当前mall），最后一个商品去除赠品 ,qs拆车数据算同个商品
      const otherCarts = this.checkout.results?.carts?.carts?.filter(item => item.mall_code != this.mallCode)
      const otherCartsWithoutPromition = filterPromitionGoods(otherCarts)

      const originList = this.mergeCartsList
      const list = originList.map(store => {
        return filterPromitionGoods(store)
      })?.filter(store => !!store.length) || []

      return otherCartsWithoutPromition.length == 0 && (list.length <= 1 && list?.[0]?.length <= 1)
    },
    showObmByAbt() {
      return this.checkout.checkoutBFFAbtInfo?.showOBMTitle?.param == 'conceal_OBM' ? false : true
    },
    mergeCartsList() {
      const originCarts = JSON.parse(JSON.stringify(this.cartModalList))

      let selfCartsList = []
      let storeCartsList = []
      let selfCarts = {}
      let storeCarts = {}

      if(this.showObmByAbt) {
        selfCartsList = []

        originCarts.forEach(item => {
          if(!storeCarts['s_' + item.store_code]){
            storeCarts['s_' + item.store_code] = []
          }
          storeCarts['s_' + item.store_code].push(item)
        })
        storeCartsList = Object.values(storeCarts) || []

      } else {
        // 展示OBM的场景，直接取后端返回的店铺名称; 不展示OBM的分支，前端写死SHEIN的店铺名称(obm的店铺名称不是SHEIN)
        // obm与自营店铺合并展示
        selfCartsList = originCarts.filter(item => item.business_model == 0) || []
        if(!!selfCartsList.length) {
          selfCarts['shein'] = selfCartsList.map(item => {
            return Object.assign({}, item, { store_title: this.language.SHEIN_KEY_PC_21125 })
          })
          selfCartsList = Object.values(selfCarts) || []
        }

        originCarts.forEach(item => {
          if(item.business_model != 0) {
            if(!storeCarts['s_' + item.store_code]){
              storeCarts['s_' + item.store_code] = []
            }
            storeCarts['s_' + item.store_code].push(item)
          }
        })
        storeCartsList = Object.values(storeCarts) || []
      }

      // 二次下单页接口无加车时间返回，直接按上述顺序展示
      const sortList = [].concat(selfCartsList).concat(storeCartsList).sort((a, b) => {
        const add_time_a = new Date(a?.[0]?.add_time).getTime()
        const add_time_b = new Date(b?.[0]?.add_time).getTime()
        return add_time_b - add_time_a
      })
      return sortList
    },
    showStoreSellerInfo(){
      return this.checkout.showStoreSellerInfoAbt
    },
    storeSellerInfo(){
      return this.checkout.storeSellerInfo?.storeSellerInfo || {}
    },
    isSelfStore() {
      return this.merchantInfoStore.business_model == 0
    },
    is1PStore() {
      return this.merchantInfoStore.business_model == 4
    },
    sellerInfo() {
      return this.storeSellerInfo[this.merchantInfoStore.store_code] || {}
    },
    bussinessName(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.bussinessName
      }
      let bussinessNameInfo = this.sellerInfo.fieldValues?.find(item => item.fieldType == 3 && item.fieldValue) || null
      bussinessNameInfo = bussinessNameInfo ? bussinessNameInfo : this.sellerInfo.fieldValues?.find(item => item.fieldType == 4 && item.fieldValue) || null
      return bussinessNameInfo ? bussinessNameInfo.fieldValue : ''
    },
    bussinessAddress(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.bussinessAddress
      }
      let bussinessNameInfo = this.sellerInfo.fieldValues?.find(item => item.fieldType == 6 && item.fieldValue) || null
      return bussinessNameInfo ? bussinessNameInfo.fieldValue : ''
    },
    bussinessRegistrationNumber(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.registrationNumber
      }
      return this.sellerInfo.codeRegisterNo || ''
    },
    vatNumber(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.vatNumber
      }
      if(this.sellerInfo.taxInfoList?.length) {
        if(this.sellerInfo.taxInfoList.length == 1) {
          return this.sellerInfo.taxInfoList[0].supplierTaxNumber || ''
        } else {
          let vatInfo = this.sellerInfo.taxInfoList.find(item => item.saleCountry == 'FR' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : this.sellerInfo.taxInfoList.find(item => item.saleCountry == 'DE' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : this.sellerInfo.taxInfoList.find(item => item.supplierTaxNumber) || null
          return vatInfo ? vatInfo.supplierTaxNumber : ''
        }
      }
      return ''
    },
    showSensitiveTips() {
      let show = false
      if(this.checkoutScene != 'Checkout') {
        // 二次下单页
        this.checkout?.results?.mallShippingOrderMethod?.mall_list?.forEach(item => {
          if (item.mall_code === this.cartsMallInfo.mall_code) {
            show = !!item.shipping_methods[0]?.cmmdty_infos?.length
          }
        })
      } else {
        // 下单页
        this.defaultShipping?.forEach(item => {
          if (item.mall_code === this.cartsMallInfo.mall_code) {
            show = !!item.shipping_method.cmmdty_infos?.length
          }
        })
      }
      
      return show
    },
    almostSoldoutTips() {
      const { CheckoutAlmostSoldout = '' } = this.userBehaviorAbt
      if (CheckoutAlmostSoldout !== 'Show') return ''
      const num = this.cartModalList.filter(item => item.realTimeInventory == 1).length
      if (num < 1) return ''
      return this.template(
        num,
        `<font color='#FE3B30'>${this.language.SHEIN_KEY_PC_29808}</font>`,
        this.language.SHEIN_KEY_PC_29807
      )
    },
    announcements() {
      let tips = []
      if(this.quickShipCheckoutStatus.quickShipStyle){
        if (this.descQuickShipTime) {
          tips.push({
            icon: {
              type: 'quickShip',
              style: {
                'color': '#198055',
                'background-color': 'transparent',
                'font-size': '12px',
                'icon-size': '16px',
                'font-weight': '700',
                'padding-left': 0,
              },
              label: this.quickShipCheckoutStatus.quickShipLabelText
            },
            text: this.descQuickShipTime
          })
        }
      } else {
        if (this.descQuickShipTime) tips.push({
          label: this.quickShipCheckoutStatus.quickShipLabelText,
          labelType: 'success',
          text: this.descQuickShipTime
        })
      }
      return tips
    },
    showBehaviorLabelByAbt() {
      const { CheckoutBehaviorShow = '' } = this.userBehaviorAbt
      return !!CheckoutBehaviorShow && CheckoutBehaviorShow != 'Hide'
    }
  },
  watch: {
    shoppingBagModalShow: {
      handler(show) {
        if (show) {
          this.handleBehaviorReport()
          this.handleCartReport()
          this.handleAlmostSoldoutReport()
          this.$nextTick(() => {
            this.handleQuickTextStyle()
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    template,
    init() {
      this.shoppingBagModalShow = true
      this.$nextTick(() => {
        let insured_goods =
          this.cartModalList?.filter((item) => item.product.insurable == 1) || []
        insured_goods.length &&
          daEventCenter.triggerNotice({
            daId: '1-7-5-21',
            extraData: {
              insured_goods: insured_goods.map(
                (item) => item.product.insurable
              ),
            },
          })
        let flashTypes = this.cartModalList?.map((item) => item.flashType) || []
        flashTypes.length &&
          daEventCenter.triggerNotice({
            daId: '1-7-7-18',
            extraData: {
              flashTypes: flashTypes,
            },
          })
        let storeInfo = []
        let storeCarts = this.mergeCartsList
        storeCarts.forEach( carts => {
          let obj = {
            store_code: carts[0].store_code,
            store_tp: carts[0].store_type == 1 ? 1 : 2,
            preferred_seller: this.isPreferredSellerStore(carts) ? 1 : 0
          }
          storeInfo.push(obj)
        })
        storeCarts.length && daEventCenter.triggerNotice({
          daId: '1-7-1-18',
          extraData: {
            storeInfo: storeInfo
          }
        })
      })
    },
    showQuickPop(flag) {
      flag && this.assignState({
        isShowQuickPop: true
      })
    },
    cartProductTotal: function (carts) {
      if (!carts.length) return ''
      let total = carts.reduce((total, item) => {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total > 1
        ? total + ' ' + this.language.SHEIN_KEY_PC_15448
        : total + ' ' + this.language.SHEIN_KEY_PC_15447
    },
    isPreferredSellerStore (carts){
      let field = this.checkoutScene != 'Checkout' ? 'preference_seller' : 'preferred_seller_store'
      return carts.find( cart => cart[field] == 1) ? true : false
    },
    openMerchantInfoModal (store) {
      this.merchantInfoStore = store
      this.merchantInfoModalShow = true
      daEventCenter.triggerNotice({
        daId: '1-11-2-3',
        extraData: {
          store_code: store.store_code
        }
      })
    },
    closeMmerchantInfoModalShow () {
      this.merchantInfoModalShow = false
      daEventCenter.triggerNotice({
        daId: '1-11-2-4',
        extraData: {
          store_code: this.merchantInfoStore.store_code
        }
      })
    },
    analysisData (id, store_code) {
      return {
        id: id,
        once: false,
        data: {
          store_code,
        }
      }
    },
    handleBehaviorReport() {
      let hasBehavior = this.cartModalList.some(cart => cart.product.showBehaviorLabel)
      let type
      if (this.showBehaviorLabelByAbt) {
        type = hasBehavior ? 3 : 1
      } else {
        type = hasBehavior ? 4 : 2
      }
      daEventCenter.triggerNotice({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'userbehavior_tips',
          type
        }
      })
    },
    handleCartReport() {
      const showDelPrice = this.cartModalList.some(cart => (cart.promotionTypeIds?.includes(12) || cart.unitPrice.amount != cart.product.retailPrice.amount || cart.product.insurable == 1))
      const showDiscount = this.cartModalList.some(cart => cart.product.unit_discount > 0)

      daEventCenter.triggerNotice({
        daId: '1-11-2-12',
        extraData: {
          scenes_a: 'add_shoppingbag_price_switch',
          type_a: !!showDelPrice ? 1 : 0,
          scenes_b: 'add_discount_logo_switch',
          type_b: !!showDiscount ? 1 : 0,
        }
      })
    },
    handleAlmostSoldoutReport() {
      const num = this.cartModalList.filter(item => item.realTimeInventory == 1).length
      num > 0 && daEventCenter.triggerNotice({
        daId: '1-11-2-13',
        extraData: {
          headline_type: `almost_soldout,${num}`
        }
      })
    },
    handleQuickTextStyle() {
      const domNode = this.$refs[this.quickShipTextRef]
      // v-for中ref获取的是数组
      if (Array.isArray(domNode) && domNode?.length > 0) {
        domNode.forEach((item) => {
          if (item.offsetHeight > this.quickShipLineHeight) { item.style.fontSize = this.quickShipFontSizeSmall }
        })
      } else if (domNode) {
        if (domNode.offsetHeight > this.quickShipLineHeight) { domNode.style.fontSize = this.quickShipFontSizeSmall }
      }
    }
  },
}
</script>

<style lang="less">
.carts-wrapper {
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
}
.bag-list-content {
  border: none;
  margin-top: 0;
  max-width: 735px;
  .platform-title {
    width: 100%;
    display: flex;
		justify-content: space-between;
    padding-bottom: 10px;
    color: #222;
    strong {
      font-size: 14px;
      color: #303030;
    }
    span {
      font-size: 13px;
      font-weight: bold;
    }
  }
}
.carts-item {
  margin: 10px 0;
}
.drawer-item-effiency {
  padding: 10px 0px 6px 0px;
  margin: 0;
  width: 100%;
}
.carts-item-effiency {
  margin-bottom: 10px;
  .margin-r(5px);
  width: 100px;

  .item-img-content {
    width: 100px;
    height: 100px;
  }

  .cart-promotion-tag label,
  .checkout-cart-label {
    padding: 2px 4px;
    line-height: 1.5;
  }
}

.bag-list__soldout {
  color: #222;
  font-size: 14px;
  margin-top: -6px;
}

.bag-list__tips {
  display: table;
  margin-top: 6px;
  &_row {
    display: table-row;
    &:first-child .bag-list__tips_cell {
      padding-top: 0;
    }
  }
  &_cell {
    display: table-cell;
    text-align: left;
    padding-top: 14px;
  }
  &_icon {
    margin-right: 16px;
  }
  &_text {
    color: #666;
    font-family: SF Pro;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
  }
}
.shopping-bag__tips-bg{
  background: #ECFCF3;
  padding: 7px 8px;
  margin: 8px 20px 0;
  border: 1px solid #BADECD;
  border-radius: 3px;
}
.shopping-bag__sensitive {
  text-align: initial;
  margin-bottom: 10px;
  line-height: 17px;
  padding: 10px 20px;
}

.shopping-bag__sensitive_icon {
  margin-right: .1rem;
}
.shopping-bag_newContainer{
  // display: inline;
  margin-bottom: 3px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.shopping-bag__tips-p0{
  display: inline-flex;
  padding-right: 0;
  padding-top: 0;
  margin-right: 5/75rem;
  max-width: 40%;
}
.shopping-bag__tips_flex{
  margin-left: 3px;
  text-align: initial;
  font-size: 12px;
  /deep/ span {
    font-style: italic;
    font-weight: 590;
    line-height: normal;
  }
}
.shopping-bage__sensitive_new {
  color: #666;
  font-family: "SF Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
.shopping-bag__tips_flex_line_limit {
  overflow: hidden;  
  text-overflow: ellipsis; 
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bag-list__effiency {
  .sui-dialog__wrapper{
    .sui-dialog__body {
      text-align: left;
    }
  }

  .sui-dialog__subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .bag-list__effiency-total {
    color: @sui_color_main;
  }

  .bag-list-body {
    margin-bottom: 8px;
  }

  .shopping-bag__item-input {
    .sui-input-number__inner {
      width: 41px;
    }
  }
}
.store-business-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .store-info-content{
    margin-right: 2px;
    display: flex;
    align-items: flex-end;
  }
  .business-info-content{
    display: flex;
    align-items: center;
    font-size: 10px;
    color:@sui_color_gray_light1;
    position: relative;
    top: 2px;
    cursor: pointer;
    &__icon {
      margin-left: 3px;
    }
    span{
      font-size: 10px;
      font-weight: normal;
    }
  }
}
.merchant-content{
  .merchant-content-item{
    margin-bottom: 16px;
    font-size: 14px;
    .item-val{
      color: @sui_color_black;
      font-weight: bold;
    }
    .is-block {
      display: block;
    }
  }
  .desc{
    font-size: 12px;
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

// shoping bage新样式 -- 抽屉
.shopbag-coupon-drawer {
  .bag-list-title{
    font-weight: 900;
  }
  .sub-title {
    border-bottom: 1px solid #E5E5E5;
    padding: 0 20px 10px;
    .effiency-total {
      color: #000;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .effiency-soldout {
      color: #222;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .sui-drawer__title {
    padding: 20px 0 12px;
  }
  .sui-drawer__body {
    padding: 0 10px 20px;
    margin: 0 10px;
    overflow-y: scroll;
  }
  .shopbag-drawer-list {
    flex: 1 1 100%;
    overflow: hidden;
    .shopbag-list-body {
      overflow: hidden;
    }
  }
  .sui-drawer__body::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  .sui-drawer__body::-webkit-scrollbar-thumb{
    border-radius: 1em;
    background-color: rgba(0,0,0,.3);
  }
  .sui-drawer__body::-webkit-scrollbar-track{
    border-radius: 1em;
    background-color: rgba(0,0,0,.1);
  }
}
.shopbag-list-content {
  .shop-platform-title {
    display: flex;
    justify-content: space-between;
    padding-right: 3px;
    margin-top: 16px;
  }
  .store-business-wrap {
    display: flex;
    align-items: center;
    .store-info-content {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
    .store-name {
      color: #000;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .store-name-new {
      font-weight: 700px;
      margin-left: 4px;
    }
  }
  .store-product-total {
    color: #222;
    text-align: right;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .business-info-wrap {
    color: #959595;
    font-family: "SF UI Text";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 6px;
    &__icon {
      margin-left: 3px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
